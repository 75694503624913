import Heading from "./Heading";
import contactStyles from "./CSS/ContactUs.module.css";
import phone_icon from "./Images/bxs_phone-call.png";
import mail_icon from "./Images/ic_sharp-email.png";
import location_icon from "./Images/carbon_location-filled.png";
import instagram from "./Images/contact_instagram.png";
import twitter from "./Images/contact_twitter.png";
import discord from "./Images/discord.png";
import plane from "./Images/letter_send.png";

const ContactUs = () => {
    return ( 
        <div className={contactStyles.main_ctn}>
            <Heading 
            header="contact us" 
            paragraph="Any question or remarks? Just write us a message!"
            />
            <div className={contactStyles.layout}>
                <div className={contactStyles.greenRectangle}>

                    <div className={contactStyles.contact_info_ctn}>
                        <h6>Contact Information</h6>
                        <p>Say something to start a live chat!</p>
                    </div>

                    <div className={contactStyles.contact_details_ctn}>
                        <div className={contactStyles.details}>
                            <img src={phone_icon}  alt="telephone icon"/>
                            <p>+2348034008366</p>
                        </div>
                        <div className={contactStyles.details}>
                            <img src={mail_icon}  alt="telephone icon"/>
                            <p>tjk.com.ng@gmail.com</p>
                        </div>
                        <div className={contactStyles.details}>
                            <img src={location_icon}  alt="telephone icon"/>
                            <p>
                                Alaro Shopping Complex Unity Road Ilorin, 
                                Opposite Kasmag Line, Kwara State.
                            </p>
                        </div>
                    </div>

                    <div className={contactStyles.socialmedia_ctn}>
                        <img src={instagram} alt="instagram icon" />
                        <img src={twitter} alt="twitter icon" />
                        <img src={discord} alt="discord icon" />
                    </div>

                    <div className={contactStyles.small_ellipse}></div>
                    <div className={contactStyles.big_ellipse}></div>
                </div>
                <div className={contactStyles.container}>
                    <form>
                        <div className={contactStyles.form_ctn}>
                            <div className={contactStyles.input_elements_ctn}>
                                <div className={contactStyles.input_elements}>
                                    <label className={contactStyles.label} >First Name</label>
                                    <input className={contactStyles.input} type="text" name="First Name" />
                                </div>
                                <div className={contactStyles.input_elements}>
                                    <label className={contactStyles.label}> Last Name</label>
                                    <input className={contactStyles.input} type="text" name="Last Name" />
                                </div>
                                <div className={contactStyles.input_elements}>
                                    <label className={contactStyles.label}> Email </label>
                                    <input className={contactStyles.input} type="email" name="Email" />
                                </div>
                                <div className={contactStyles.input_elements}>
                                    <label className={contactStyles.label}>Phone Number</label>
                                    <input className={contactStyles.input} type="text" name="name" />
                                </div>
                            </div>
                            <div className={contactStyles.checkbox_main_ctn}>
                                <label className={contactStyles.label}>Select Subject?</label>
                                <div className={contactStyles.checkbox_elements_ctn}>
                                    <div className={contactStyles.checkbox_elements}>
                                        <label className={contactStyles.label}>General Inquiry</label>
                                        <input type="checkbox" className={contactStyles.checkbox} />
                                    </div>
                                    <div className={contactStyles.checkbox_elements}>
                                        <label className={contactStyles.label}>General Inquiry</label>
                                        <input type="checkbox" className={contactStyles.checkbox} />
                                    </div>
                                    <div className={contactStyles.checkbox_elements}>
                                        <label className={contactStyles.label}>General Inquiry</label>
                                        <input type="checkbox" className={contactStyles.checkbox} />
                                    </div>
                                    <div className={contactStyles.checkbox_elements}>
                                        <label className={contactStyles.label}>General Inquiry</label>
                                        <input type="checkbox" className={contactStyles.checkbox} />
                                    </div>
                                </div>
                            </div>
                            <div className={contactStyles.messageInput_element}>
                                    <label className={contactStyles.label}>Message</label>
                                    <input className={contactStyles.messageInput} type="text" name="name" placeholder="Write your message.." />
                            </div>
                            <div className={contactStyles.button_ctn}>
                                <div className={contactStyles.button}>
                                Send Message
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div className={contactStyles.plane}>
                    <img src={plane} />
                </div>
            </div>
        </div>
     );
}
 
export default ContactUs;