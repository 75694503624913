import reviewStatStyles from "../Components/CSS/ReviewStats.module.css";


const ReviewStats = (props) => {
    return ( 
        <div className={reviewStatStyles.container}>
            <div className={reviewStatStyles.image}>
                <img className={reviewStatStyles.image} src={props.icon} />
            </div>
            <div className={reviewStatStyles.text}>
                <h5 className={reviewStatStyles.header}>{props.title}</h5>
                <p className={reviewStatStyles.paragraph}>{props.paragraph}</p>
            </div>
        </div>
     );
}
 
export default ReviewStats;