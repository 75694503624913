import logo from './logo.svg';
import './App.css';
import Header from './Components/Header';
import Navbar from "./Components/Navbar";
import Main from './Components/Main';
import Footer from './Components/Footer';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom"
import ContactUs from './Components/ContactUs';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <div>
          <Switch>
            <Route exact path="/">
              <Main />
            </Route>
            <Route path="/contact">
              <ContactUs />
            </Route>
          </Switch>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
