import headingStyles from  "../Components/CSS/Heading.module.css";
const Heading = (props) => {
    return ( 
        <div className={headingStyles.heading}>
            <h3>{props.header}</h3>
            <p className={headingStyles.headingParagraph}>{props.paragraph}</p>
        </div>
     );
}
 
export default Heading;