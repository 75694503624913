export const CoursesData = [
    {
        "title": "software development (mobile & web)", 
        "src": "Images/software-development-card.png", 
        "id": 1,
        "url": ""
    },
    
    {
        "title": "digital marketing",
        "src": "Images/digital-marketing-card.png", 
        "id": 2,
        "url": ""
    },
    
    {
        "title": "digital tech training and mentorship", 
        "src": "Images/digital-tech-training-card.png", 
        "id": 3,
        "url": ""
    },
    
    {
        "title": "personnel recruitment and management", 
        "src": "Images/personal-recruitment-card.png", 
        "id": 4,
        "url": ""
    },
    
    {
        "title": "real estate agent and development", 
        "src": "Images/real-estate-card.png", 
        "id": 5,
        "url": ""
    },
    
    {
        "title": "farming, farm produc sales & marketing", 
        "src": "Images/farming-card.png", 
        "id": 6,
        "url": ""
    }
]