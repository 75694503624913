import ReviewStyles from "../Components/CSS/Review.module.css"; 
import ReviewStats from "./ReviewStats";
import members from "./Images/members.png";
import Clubs from "./Images/clubs.png";
import Payments from "./Images/payment.png";
import eventBooking from "./Images/events.png";

const Review = (props) => {
    return ( 
        <div className={ReviewStyles.silverContainer}>
            <div className={ReviewStyles.container}>
            <div className={ReviewStyles.textContainer}>
                <h5 className={ReviewStyles.normalText}>Helping a local <span className={ReviewStyles.greenText}>business reinvent itself</span></h5>
                <p className={ReviewStyles.paragraph}>We reached here with our hardwork and dedication</p>
            </div>
            <div className={ReviewStyles.reviewStats} >
                <ReviewStats title="2,245,341" paragraph="Members" icon={members}/>
                <ReviewStats title="46,328" paragraph="Clubs" icon={Clubs}/>
                <ReviewStats title="828,867" paragraph="Event Bookings" icon={eventBooking}/>
                <ReviewStats title="1,926,436" paragraph="Payments" icon={Payments}/>
            </div>
        </div>
        </div>

     );
}
 
export default Review;