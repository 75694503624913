export const ManagementCardsData = [
        {
            "title": "Membership Organisations" , 
            "detail": "Our membership management software provides full automation of membership renewals and payment", 
            "iconUrl": "Images/membership.png" , 
            "id": 1
        },
        {
            "title": "National Associations" , 
            "detail": "Our membership management software provides full automation of membership renewals and payments", 
            "iconUrl": "Images/national-associations.png" , 
            "id": 2
        },

        {
            "title": "Clubs And Groups" , 
            "detail": "Our membership management software provides full automation of membership renewals and payments", 
            "iconUrl": "Images/clubs-and-groups.png" ,
            "id" : 3
        },
    ]