import MainStyles from "../Components/CSS/Main.module.css";
import Courses from "./Courses";
import client1 from "./Images/client1.png";
import client2 from "./Images/client2.png";
import client3 from "./Images/client3.png";
import client4 from "./Images/client4.png";
import client5 from "./Images/client5.png";
import client6 from "./Images/client6.png";
import client7 from "./Images/client7.png";
import Heading from "./Heading";
import ManagementCards from "./ManagementCards";
import Review from "./Review";
import Button from "./Button";
import { useState , useEffect } from "react";
import { CoursesData } from "./CoursesData";
import { ManagementCardsData } from "./ManagementCardsData";
import Header from "./Header";

const Main = () => {
    const [course , setCourses] = useState(null);

    useEffect(() => {
        fetch(CoursesData)
        .then(res => {
            return res.json();
        })
        .then(data => {
            setCourses(data);
        })
    }, [])

    const [card, setCards]= useState(null);

    useEffect(() => {
        fetch(ManagementCardsData)
        .then(res => {
            return res.json();
        })
        .then(data => {
            setCards(data);
        })
    }, [])
    return ( 
        <main>
            <Header />
            <Heading 
                header="OUR SERVICES"
                paragraph="The Nextcent blog is the best place to read about the latest membership insights,
                trends and more. See who s joining the community, read about how our community
                are increasing their membership income and lot s more."
            />
            <div className={MainStyles.coursesContainer}>
                {CoursesData && <Courses courses={CoursesData}/>}
            </div>
            <Heading 
                header="Our Clients"
                paragraph="We have been working with some Fortune 500+ clients"
            />
            <div className={MainStyles.clientsImageContainer}>
                <div>
                    <img src={client1} className={MainStyles.clientImages} alt="client icon"/>
                </div>
                <div>
                    <img src={client2} className={MainStyles.clientImages} alt="client icon"/>
                </div>
                <div>
                    <img src={client3} className={MainStyles.clientImages} alt="client icon"/>
                </div>
                <div>
                    <img src={client4} className={MainStyles.clientImages} alt="client icon"/>
                </div>
                <div>
                    <img src={client5} className={MainStyles.clientImages} alt="client icon"/>
                </div>
                <div>
                    <img src={client6} className={MainStyles.clientImages} alt="client icon"/>
                </div>
                <div>
                    <img src={client7} className={MainStyles.clientImages} alt="client icon"/>
                </div>
            </div>
            <Heading 
                header="Manage your entire company in a single software"
                paragraph="Who is Nextcent suitable for?"
            />
            <div className={MainStyles.managementContainer}>
                {ManagementCardsData && <ManagementCards cards={ManagementCardsData}/>}
            </div>
            <Review />
            <div className={MainStyles.demoContainer}>
                <div className={MainStyles.ctn}>
                    <h2 className={MainStyles.need}>Need a management software?</h2>
                    <a href=""><Button text="Get a Demo  &#8594;"/></a>
                </div>
            </div>
        </main>
    );
}
 
export default Main;