import  "../Components/CSS/Navbar.css";
import Logo from "./Logo";
import hamburger from "./Images/menu.png"
import Button from "./Button";
import closeMenu from "./Images/Close.png";
import { useState } from "react";
import {Link} from "react-router-dom";
import {useLocation} from "react-router-dom";


const Navbar = () => {

    const [isActive, setIsActive] = useState(false);

    const handleClick = event => {
        // 👇️ toggle isActive state on click
        setIsActive(current => !current);
    };
    //assigning location variable 
    const location = useLocation();
    
    //destructuring pathname from location
    const {pathname} = location;

    //javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");

    return ( 
        <nav>
            <div>
                <div className="navbar">
                    <Logo />
                    <div className="linksContainer">
                        <div className="listItemContainer">
                           <li className="listItemElements"> <Link className={splitLocation[1] === "" ? "Active" : ""} to="/">Home</Link></li>
                           <li className="listItemElements"> <Link className={splitLocation[1] === "service" ? "Active" : ""} to="/">Service</Link></li>
                           <li className="listItemElements"> <Link className={splitLocation[1] === "about" ? "Active" : ""} to="/">About Us</Link></li>
                           <li className="listItemElements"> <Link className={splitLocation[1] === "contact" ? "Active" : ""} to="/contact">Contact Us</Link></li>
                           <li className="listItemElements"> <Link className={splitLocation[1] === "pricing" ? "Active" : ""} to="/">Pricing</Link></li>
                        </div>
                        <a href=""><Button text="Training &#8594;" /></a>
                    </div>
                    <img src={hamburger} className="menu" onClick={handleClick}/>
                </div>
            </div>
            
            <div>
                <div className={isActive ? 'menuActive' : 'menuInActive'}>
                    <div className="mobileLinksContainer">
                        <div className="close-ctn">
                            <img src={closeMenu} alt="close button" onClick={handleClick} className="closeMenu"/>
                        </div>
                        <li className="listItemElements"> <Link className={splitLocation[1] === "" ? "Active" : ""} to="/">Home</Link></li>
                           <li className="listItemElements"> <Link className={splitLocation[1] === "service" ? "Active" : ""} to="/">Service</Link></li>
                           <li className="listItemElements"> <Link className={splitLocation[1] === "about" ? "Active" : ""} to="/">About Us</Link></li>
                           <li className="listItemElements"> <Link className={splitLocation[1] === "contact" ? "Active" : ""} to="/contact">Contact Us</Link></li>
                           <li className="listItemElements"> <Link className={splitLocation[1] === "pricing" ? "Active" : ""} to="/">Pricing</Link></li>
                    </div>
                </div>
            </div>
        </nav>
     );
}
 
export default Navbar;