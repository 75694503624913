import { useState } from "react";
import headerStyles from "../Components/CSS/Header.module.css";
import Button from "./Button";
import right from "./Images/arrow-right.png";
import left from "./Images/arrow-left.png";

import {carouselData} from "./CarouselData";

const Header = () => {
    
    const [currSlide, setCurrSlide] = useState(0);

    let indicatorActive = '#43A046';
    let indicatorInActive = '#A5D6A7';
    const [indicator1, setIndicator1] = useState(indicatorActive);
    const [indicator2, setIndicator2] = useState(indicatorInActive);
    const [indicator3, setIndicator3] = useState(indicatorInActive);


    //functions to set slide position and indicator color when they are clicked
    const setSlide1 = () =>{
        firstIndicator();
        setCurrSlide(0);
    }

    const setSlide2 = () =>{
        setCurrSlide(1);
        secondIndicator();
    }

    const setSlide3 = () =>{
        setCurrSlide(2);
        thirdIndicator();
    }

    const firstIndicator = () =>{
        setIndicator1(indicatorActive);
        setIndicator2(indicatorInActive)
        setIndicator3(indicatorInActive)
    }
    const secondIndicator = () =>{
        setIndicator2(indicatorActive);
        setIndicator1(indicatorInActive)
        setIndicator3(indicatorInActive)
    }
    const thirdIndicator = () =>{
        setIndicator3(indicatorActive);
        setIndicator1(indicatorInActive)
        setIndicator2(indicatorInActive)
    }

    //function to go to the previous slide on the carousel when the left arrow has been clicked
    const slideDecr = () => {
        currSlide > 0 && setCurrSlide(currSlide - 1);
        
        if (currSlide == 0) {
            firstIndicator();
        }
        if (currSlide == 1) {
            firstIndicator();
        }
        if (currSlide == carouselData.length -1) {
            secondIndicator();
        }
    };
    //function to go to the next slide on the carousel when the right arrow has been clicked
    const slideIncr = () => {
        currSlide < carouselData.length -1 && setCurrSlide(currSlide + 1);
        if (currSlide == 0) {
            secondIndicator();
        }
        if (currSlide == 1) {
            thirdIndicator();
        }
        if (currSlide == carouselData.length - 1) {
            thirdIndicator();
        }
    }

    return ( 
        <header className={headerStyles.header}>
            <div className={headerStyles.carousel}>
                <div className={headerStyles.slides}>

                    <div className={headerStyles.carouselTextContainer}>
                        <h1 className={headerStyles.headerText}>{carouselData[currSlide].brownText} 
                            <span className={headerStyles.greenText}>{carouselData[currSlide].greenText}</span>
                        </h1>
                        <p className={headerStyles.paragraph}>{carouselData[currSlide].paragraph}</p>
                        <a href={carouselData[currSlide].url} ><Button text={carouselData[currSlide].buttonText} /></a>
                    </div>

                    <div className={headerStyles.imageContainer}>
                        <img src={carouselData[currSlide].src} className={headerStyles.hero} alt={carouselData[currSlide].alt}/>
                    </div>

                </div>
                <div className={headerStyles.indicatorsContainer}>
                    <div><img src={left} className={headerStyles.left} onClick={slideDecr}/></div>
                    <div className={headerStyles.indicators} onClick={setSlide1} style={{backgroundColor: indicator1}}></div>
                    <div className={headerStyles.indicators} onClick={setSlide2} style={{backgroundColor: indicator2}}></div>
                    <div className={headerStyles.indicators} onClick={setSlide3} style={{backgroundColor: indicator3}}></div>
                    <div><img src={right} className={headerStyles.right} onClick={slideIncr}/></div>
                </div>
            </div>

        </header>
     );
}
 
export default Header;