import heroSlide1 from "./Images/hero.png";
import heroSlide2 from "./Images/software-development-card.png";
import heroSlide3 from "./Images/digital-marketing-card.png";

export const carouselData = [
    {
        brownText: "Lessons and insights",
        greenText: " from years of experience",
        paragraph: "where to grow your business: site or social media?" ,
        id: 1,
        src: heroSlide1,
        url: "",
        buttonText: "Let's Go",
        alt: "image for slide one"
    },
    {
        brownText: "Lessons and progress",
        greenText: " from years of experience",
        paragraph: "where to grow your business: site or social media?" ,
        id: 2,
        src: heroSlide2,
        url: "",
        buttonText: "Find More",
        alt: "image for slide two"
    },
    {
        brownText: "Lessons and acheivements",
        greenText: " from years of experience",
        paragraph: "where to grow your business: site or social media?" ,
        id: 3,
        src: heroSlide3,
        url: "",
        buttonText: "Come on !",
        alt: "image for slide three"
    }
]