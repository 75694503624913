import CourseStyles from "../Components/CSS/courses.module.css"


const Courses = ({courses}) => {

    return ( 
        <>
            {courses.map((course) => (
                <div className={CourseStyles.container}>
                    <img src={course.src} className={CourseStyles.courseImage} />
                    <div className={CourseStyles.details}>
                        <h4>{course.title}</h4>
                        <a href={course.url}><p className={CourseStyles.readMore}>Read more &#8594;</p></a>
                    </div>
                </div>
            ))}
        </>
     );
}
 
export default Courses;