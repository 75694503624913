import CardStyles from  "../Components/CSS/ManagementCards.module.css";

const ManagementCards = ({cards}) => {

    return ( 
        <>
            {cards.map((card) => (
                <div className={CardStyles.managementCard} key={card.id}>
                    <img src={card.iconUrl} className={CardStyles.cardImage}/>
                    <h5 className={CardStyles.title}>{card.title}</h5>
                    <p className={CardStyles.detail}>{card.detail}</p>
                </div>
            ))}
        </>

     );
}
 
export default ManagementCards;