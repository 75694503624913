import FooterStyles from "../Components/CSS/Footer.module.css";
import Logo from "./Logo";
import instagram from "./Images/instagram.png";
import youtube from "./Images/youtube.png";
import twitter from "./Images/twitter.png";
import internet from "./Images/www.png";
import paperPlane from "./Images/paper-plane.png";
import {Link} from "react-router-dom"

const Footer = () => {


    return ( 
        <div className={FooterStyles.container}>
            <div className={FooterStyles.first_column}>
                <Logo /> 
                <div className={FooterStyles.copyrightContainer}>
                    <p className={FooterStyles.copyright}>Copyright &copy; 2023 tjk groups.</p>
                    <p className={FooterStyles.copyright}>All rights reserved</p>
                </div>
                <div className={FooterStyles.socialmediaContainer}>
                    <a href=""><img src={instagram} className={FooterStyles.icons} alt="instagram logo" /></a>
                    <a href=""><img src={internet} className={FooterStyles.icons} alt="web logo"/></a>
                    <a href=""><img src={twitter} className={FooterStyles.icons}  alt="twitter logo"/></a>
                    <a href=""><img src={youtube} className={FooterStyles.icons}  alt="youtube logo"/></a>
                </div>
            </div>
            <div className={FooterStyles.second_column}>
                <div className={FooterStyles.col_2}>
                    <h5 className={FooterStyles.title}>Company</h5>
                    <a href=""><p className={FooterStyles.links}>About us</p></a>
                    <a href=""><p className={FooterStyles.links}>Blog</p></a>
                    <Link to="/contact"><p className={FooterStyles.links}>Contact us</p></Link>
                    <a href=""><p className={FooterStyles.links}>Pricing</p></a>
                    <a href=""><p className={FooterStyles.links}>Testimonials</p></a>
                </div>
                <div className={FooterStyles.col_2}>
                    <h5 className={FooterStyles.title}>Support</h5>
                    <a href=""><p className={FooterStyles.links}>Help center</p></a>
                    <a href=""><p className={FooterStyles.links}>Terms of service</p></a>
                    <a href=""><p className={FooterStyles.links}>Legal</p></a>
                    <a href=""><p className={FooterStyles.links}>Privacy policy</p></a>
                    <a href=""><p className={FooterStyles.links}>Status</p></a>
                </div>
            </div>
            <div className={FooterStyles.third_column}>
                <h5 className={FooterStyles.title}>Stay up to date</h5>
                <div className={FooterStyles.inputContainer}>
                    <input placeholder="Your email address" type="email" className={FooterStyles.input}></input>
                    <img src={paperPlane} alt="send icon" className={FooterStyles.paperPlane}/>
                </div>
            </div>
        </div>
     );
}
 
export default Footer;