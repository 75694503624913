import logoStyles from "./CSS/Logo.module.css"
import logo from "./Images/Logo.png";
import {Link} from "react-router-dom";

const Logo = () => {
    return ( 
        <div className={logoStyles.container}>
            <Link to="/"><img src={logo}/></Link>
        </div>
     );
}
 
export default Logo;